import angular from 'angular';

import ERPmanager from './ERPmanager.service';
import LegalFileFactory from './legal-file.service';
import DocumentDownloadFactory from './document-download.service';
import ClaimFactory from './claim-factory.service';
import DocumentChangeFactory from './document-change.service';
import fileUploaderGenerator from './fileUploaderGenerator.service';
import fileUploader from './fileUploader.service';
import sameFlightCSV from './same-flightCSV.service';
import DocumentTransformationFactory from './document-transformation.service';
import DocumentCopyFactory from './document-copy.service';
import FlightFactory from './flight.service';
import PanelTools from './PanelTools.service';
import CostsProceduresService from './CostsProcedures.service';
import DownloadCSVService from './DownloadCSV.service';
import LocalStorageService from './localStorage.service';
import ProductService from './ProductService';
import CreateNewClaim from './CreateNewClaimService';
import MassiveAgreements from './massive-agreements.service';
import stateManagerService from './stateManager.service';

angular
  .module('reclamador.services', [])
  .service('ERPmanager', ERPmanager)
  .factory('LegalFileFactory', LegalFileFactory)
  .factory('DocumentDownloadFactory', DocumentDownloadFactory)
  .factory('ClaimFactory', ClaimFactory)
  .factory('DocumentChangeFactory', DocumentChangeFactory)
  .factory('fileUploaderGenerator', fileUploaderGenerator)
  .factory('fileUploader', fileUploader)
  .factory('sameFlightCSV', sameFlightCSV)
  .factory('DocumentTransformationFactory', DocumentTransformationFactory)
  .factory('DocumentCopyFactory', DocumentCopyFactory)
  .factory('FlightFactory', FlightFactory)
  .factory('PanelTools', PanelTools)
  .factory('CostsProceduresService', CostsProceduresService)
  .factory('DownloadCSVService', DownloadCSVService)
  .factory('$localStorage', LocalStorageService)
  .factory('ProductService', ProductService)
  .factory('CreateNewClaim', CreateNewClaim)
  .factory('MassiveAgreements', MassiveAgreements)
  .factory('stateManagerService', stateManagerService);
