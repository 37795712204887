'use strict';

import angular from 'angular';

let env = angular.module("reclamador.version", [])

.constant("version", "113.1.0")

;

export default { env, version: "113.1.0" };
