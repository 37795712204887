stateManagerService.$inject = ['$http', 'serverAddress'];

export default function stateManagerService($http, serverAddress) {
  return {retryLleida, getChangeStateData, changeState};

  function retryLleida(claimId, claimPrefix) {
    const url = serverAddress.getBaseUrl() + 'common/retry-lleida-pretrial/';
    const requestData = {
      claim_id: claimId,
      claim_prefix: claimPrefix
    };
    return $http.post(url, requestData);
  }

  function getChangeStateData(requestData) {
    const url = serverAddress.getBaseUrl() + 'common/claim/state/change/check/';
    return $http.post(url, requestData);
  }

  function changeState(serviceURL, requestData) {
    const url = serverAddress.getBaseUrl() + serviceURL;
    return $http.post(url, requestData);
  }
}
