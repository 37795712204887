const SEGMENT_ID = 1,
  MODEL_PROVIDER = 'AirlineClaim',
  DJANGO_MODEL = 'AirlineComplaint';

export default {
  SEGMENT_ID,
  MODEL_PROVIDER,
  SEGMENT_PREFIX: 'AE',
  ALIAS: 'airline',
  DJANGO_MODEL: DJANGO_MODEL,
  VERBOSE_NAME: 'Reclamación de aerolíneas', // optional
  permissions: {
    downloadCSVPermission: 'segment-perobject-AE-crm_download_csv'
  },
  permissions2: {
    downloadCSVPermission: 'segment-global-crm_view_flights_list'
  },
  CLAIM_DICTIONARIES: {
    salesman: 'airlinesalesmen',
    users: 'lawyers',
    claimStates: {
      entity: 'claimstates',
      data: { segments__id: SEGMENT_ID, costs: false }
    },
    claimTypes: {
      entity: 'claimtypes',
      data: { type: 'airline' }
    },
    sources: 'claimsources',
    courtlawyers: 'courtlawyers',
    externalcourtlawyers: 'externalcourtlawyers_forairlines',
    incentiveStates: 'incentiveStates',
    procuradores: {
      entity: 'procuradores',
      data: { is_airline: true }
    },
    otherpartlawyers: 'otherpartlawyers',
    courts: 'courts',
    saleStates: {
      entity: 'salesclaimstates',
      data: { segments__id: SEGMENT_ID }
    },
    statesWithEmail: {
      entity: 'statesWithPreviewEmail',
      data: { django_class: DJANGO_MODEL }
    },
    trialMunicipalities: 'trialMunicipalities',
    docxtemplates: {
      entity: 'docxtemplates',
      data: { active: true, segments__id: SEGMENT_ID, target_holder: 'claim' }
    }
  },

  airlinesWithWarningNoFlightTicketNumber: [
    559 // Air Europa
  ],

  airlinesFlightTicketNumberAutocomplete: {
    559: '996' // Air Europa
  },

  changeAmountDialogOpts: {
    backdrop: true,
    keyboard: true,
    backdropClick: true,
    template: require('../../../../partials/common/modals/case-change-amount.html'),
    controller: 'ConfirmAmountChangeCtrl'
  },

  // Change source to claims of the same flight
  ClaimsChangeSourceDialogOpts: {
    backdrop: true,
    keyboard: true,
    backdropClick: true,
    template: require('../../../../partials/common/modals/claim-change-source.html'),
    controller: 'ClaimsChangeSourceCtrl'
  },

  confirmChangeFlightSentencesOpts: {
    backdrop: true,
    keyboard: true,
    backdropClick: true,
    template: require('../../../../partials/common/modals/confirm-change-flight-sentences.html'),
    controller: 'ConfirmChangeFlightSentencesCtrl'
  },

  luggageSubTypes: [
    { label: 'L', value: 'Perdida' },
    { label: 'D', value: 'Dañado' },
    { label: 'R', value: 'Retraso' },
    { label: 'B', value: 'Dañado y Retraso' }
  ],

  tabConfig: [
    {
      heading: 'Resumen',
      indexKey: 'resumen',
      template: 'js/modules/segments/airline/partials/detail/summary.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Indemnización',
      indexKey: 'indemnizacion',
      template: 'js/modules/segments/airline/partials/detail/amount.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Detalles',
      indexKey: 'detalles',
      template: 'js/modules/segments/airline/partials/detail/details.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Documentación',
      indexKey: 'documentacion',
      template: 'js/modules/segments/airline/partials/detail/documentation.html'
    },
    {
      heading: 'Historial',
      indexKey: 'historial',
      template: '/partials/common/detail/historic.html',
      headingBadge: null
    },
    {
      heading: 'Vuelo',
      indexKey: 'vuelo',
      template: 'js/modules/segments/airline/partials/detail/info-flight.html',
      select: null
    },
    {
      heading: 'Mismo vuelo',
      indexKey: 'mismo-vuelo',
      template: 'js/modules/segments/airline/partials/detail/same-flight.html',
      select: null
    },
    {
      heading: 'Mismo expediente',
      indexKey: 'mismo-expediente',
      template: 'js/modules/segments/airline/partials/detail/same-legalfile.html',
      select: null
    },
    {
      heading: 'Mismo cliente',
      indexKey: 'mismo-cliente',
      template: '/partials/common/detail/same_client/base.html'
    },
    {
      heading: 'Invitaciones',
      indexKey: 'invitaciones',
      template: 'js/modules/segments/airline/partials/detail/invitations.html',
      select: null
    },
    {
      heading: 'Administración',
      indexKey: 'administracion',
      template: '/partials/common/detail/administration.html',
      select: null,
      headingBadge: null,
      permission: 'segment-global-crm_view_administration_tab'
    }
  ],
  defaultColumnDef: [
    { headerName: 'ID', fieldName: 'id', sortable: true, alwaysVisible: true },
    { headerName: 'Tipo', fieldName: 'type_name', sortable: true },
    { headerName: 'Idioma', fieldName: 'client_language', sortable: true },
    { headerName: 'Dominio', fieldName: 'domain', sortable: true },
    { headerName: 'No show', fieldName: 'no_show', sortable: true },
    { headerName: 'Cliente', fieldName: 'client_surnames', sortable: true },
    { headerName: 'Correo cliente', fieldName: 'client_email', sortable: true, hidden: true },
    { headerName: 'Provincia cliente', fieldName: 'client_province', sortable: true, hidden: true },
    { headerName: 'Tlfno', fieldName: 'client_phone', sortable: true, hidden: true },
    {
      headerName: 'Cuenta bancaria',
      fieldName: 'client_has_bank_account',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Cuenta Paypal',
      fieldName: 'client_has_paypal_account',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha cuenta bancaria',
      fieldName: 'date_bank_account',
      sortable: true,
      hidden: true
    },
    { headerName: 'Cómo nos conoció', fieldName: 'how_met_us', sortable: true, hidden: true },
    { headerName: 'Fuente invitador', fieldName: 'invited', sortable: true, hidden: true },
    { headerName: 'Finalizada', fieldName: 'finished', sortable: true },
    { headerName: 'Fecha Finalizada', fieldName: 'date_finished', sortable: true },
    { headerName: 'Finalizado Cliente', fieldName: 'user_finished', sortable: true },
    { headerName: 'Vuelo', fieldName: 'flight_code', sortable: true },
    { headerName: 'Aerolinea', fieldName: 'airline_raw', sortable: true, hidden: true },
    { headerName: 'F. Vuelo', fieldName: 'scheduled_departure', sortable: true },
    { headerName: 'Estado vuelo', fieldName: 'flight_status', sortable: true },
    { headerName: 'Vuelo validado', fieldName: 'validated_flight', sortable: true },
    { headerName: 'Vuelo negociado', fieldName: 'negotiated_flight', sortable: true },
    { headerName: 'Vuelo ganado', fieldName: 'wonflight', sortable: true },
    { headerName: 'Abogado', fieldName: 'lawyer', sortable: true },
    { headerName: 'Validado por', fieldName: 'validator_lawyer', sortable: true },
    { headerName: 'Estado', fieldName: 'state_name', sortable: true },
    {
      headerName: 'Asistencia del cliente al juicio',
      fieldName: 'client_attendance',
      sortable: true
    },
    { headerName: 'Comercial', fieldName: 'salesman', sortable: true },
    { headerName: 'Comercial Vuelo', fieldName: 'flight_salesman', sortable: true },
    { headerName: 'Estado C.', fieldName: 'sales_state_name', sortable: true },
    { headerName: 'Estado Incentivo', fieldName: 'incentive_state', sortable: true },
    { headerName: 'Fase', fieldName: 'phase_name', sortable: true },
    { headerName: 'Fuente', fieldName: 'source_name', sortable: true },
    { headerName: 'Agencia', fieldName: 'agency.name', sortable: true, hidden: true },
    { headerName: 'Consolidador', fieldName: 'consolidator_name', sortable: true, hidden: true },
    { headerName: 'Campaña', fieldName: 'campaign_name', sortable: true, hidden: true },
    { headerName: 'SEM', fieldName: 'has_sem_gclid', sortable: true, hidden: true },
    { headerName: 'SEM (Valor)', fieldName: 'sem_gclid', sortable: true, hidden: true },
    { headerName: 'Network', fieldName: 'sem_network', sortable: true, hidden: true },
    { headerName: 'Placement', fieldName: 'sem_placement', sortable: true, hidden: true },
    { headerName: 'Campaign', fieldName: 'sem_campaign', sortable: true, hidden: true },
    {
      headerName: 'Ultimo cambio estado',
      fieldName: 'laststatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado',
      fieldName: 'laststatechangeuser',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Comentario cambio estado',
      fieldName: 'laststatecomment',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Ult. cambio comercial',
      fieldName: 'lastsalesstatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario ult. cambio comercial',
      fieldName: 'lastsalesstatechangeuser',
      sortable: true,
      hidden: true
    },
    { headerName: 'Último comentario', fieldName: 'last_comment', sortable: true, hidden: true },
    {
      headerName: 'Usuario Último comentario',
      fieldName: 'last_comment_user',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha Último comentario',
      fieldName: 'last_comment_date',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Último cambio estado incentivo',
      fieldName: 'last_incentive_state_change',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Tipo de resolución',
      fieldName: 'resolution_type',
      sortable: true,
      hidden: true
    },
    { headerName: 'Cantidad reclamada', fieldName: 'total_amount', sortable: true, hidden: true },
    {
      headerName: 'Cantidad acuerdo prejudicial',
      fieldName: 'agreement_amount',
      sortable: true,
      hidden: true
    },
    { headerName: 'Referencia', fieldName: 'agreement_ref', sortable: true, hidden: true },
    { headerName: 'Nº Expediente', fieldName: 'legalfile_num', sortable: true, hidden: true },
    { headerName: 'Autos', fieldName: 'legalfile_autos', sortable: true, hidden: true },
    { headerName: 'Cantidad pagada', fieldName: 'obtained_amount', sortable: true, hidden: true },
    { headerName: 'Fecha vista', fieldName: 'view_date', sortable: true, hidden: true },
    { headerName: 'Menores', fieldName: 'minors', sortable: true, hidden: true },
    {
      headerName: 'Invitaciones enviadas',
      fieldName: 'num_sent_invitations',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Invitaciones aceptadas',
      fieldName: 'num_accepted_invitations',
      sortable: true,
      hidden: true
    },
    { headerName: 'Num. mismo vuelo', fieldName: 'num_same_flight', sortable: true, hidden: true },
    {
      headerName: 'Vuelo con sentencias favorables',
      fieldName: 'flight.has_favorable_sentences',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Vuelo con sentencias desfavorables',
      fieldName: 'flight.has_unfavorable_sentences',
      sortable: true,
      hidden: true
    },
    { headerName: 'Creado', fieldName: 'date_added', sortable: true },
    { headerName: 'Modificado', fieldName: 'date_updated', sortable: true },
    { headerName: 'Fecha bastanteo', fieldName: 'date_bastanteo', sortable: true },
    { headerName: 'Puenteo', fieldName: 'bypass_date', sortable: true, hidden: true },
    { headerName: 'Procurado', fieldName: 'procurado', sortable: true, hidden: true },
    { headerName: 'Partner', fieldName: 'partner', sortable: true, hidden: true },
    {
      headerName: 'Partner Tracking',
      fieldName: 'partner_tracking_id',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Tiene tarjeta de embarque',
      fieldName: 'boarding_pass_uploaded',
      sortable: true,
      hidden: true
    },
    { headerName: 'Numero documentos', fieldName: 'num_docs', sortable: true, hidden: true },
    { headerName: 'Cantidad reclamada', fieldName: 'compensation', sortable: true, hidden: true },
    {
      headerName: 'Primer vertical',
      fieldName: 'first_claim_segment',
      sortable: true,
      hidden: true
    },
    { headerName: 'Primer producto', fieldName: 'first_claim_type', sortable: true, hidden: true },
    { headerName: 'Cancelación +14 días', fieldName: 'more_14_days', sortable: true, hidden: true },
    {
      headerName: 'Documentos pendientes',
      fieldName: 'pending_docs',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Comentarios documentos pendientes',
      fieldName: 'pending_docs_comments',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha alta cliente',
      fieldName: 'client_date_added',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha primera reclamación válida',
      fieldName: 'first_claim_date_added',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Tipo de facturación',
      fieldName: 'is_immediate',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha a facturar',
      fieldName: 'billing_date',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Cedida',
      fieldName: 'transfered_to_investor',
      sortable: true
    },
    {
      headerName: 'Fecha cesión',
      fieldName: 'transfered_to_investor_date',
      sortable: true
    },
    {
      headerName: 'Cartera',
      fieldName: 'investor_business_name',
      sortable: true
    },
    {
      headerName: 'Comprada',
      fieldName: 'bought_from_portfolio',
      sortable: true
    },
    {
      headerName: 'Comprado a',
      fieldName: 'bought_portfolio_business_name',
      sortable: true
    },
    {
      headerName: 'Fecha compra',
      fieldName: 'bought_date',
      sortable: true
    },
    {
      headerName: 'Email cartera cliente',
      fieldName: 'email_bought_client',
      sortable: true
    },
    {
      headerName: 'Teléfono cartera cliente',
      fieldName: 'phone_bought_client',
      sortable: true
    }
  ],
  filterFieldInfo: {
    date_bastanteo__gte: {
      label: 'Fecha bastanteo desde',
      type: 'date',
      column: 'claim.date_bastanteo',
      order: '>='
    },
    date_bastanteo__lte: {
      label: 'Fecha bastanteo hasta',
      type: 'date',
      column: 'claim.date_bastanteo',
      order: '<='
    },
    scheduled_departure__gte: {
      label: 'Fecha vuelo desde',
      type: 'date',
      column: 'claim.scheduled_departure',
      order: '>='
    },
    scheduled_departure__lte: {
      label: 'Fecha vuelo hasta',
      type: 'date',
      column: 'claim.scheduled_departure',
      order: '<'
    },
    laststatechange__gte: {
      label: 'Último cambio de estado desde',
      type: 'date',
      column: 'claim.laststatechange',
      order: '>='
    },
    laststatechange__lte: {
      label: 'Último cambio de estado hasta',
      type: 'date',
      column: 'claim.laststatechange',
      order: '<'
    },
    flight_code: { label: 'Vuelo', column: 'flight_code' },
    airline_raw: {
      label: 'Aerolínea',
      column: 'airline_raw',
      json_value: 'true',
      use_field: 'name'
    },
    agency_name: { label: 'Agencia', json_value: 'true', column: 'agency.name', use_field: 'name' },
    campaign_name: {
      label: 'Campaña',
      column: 'marketingcampaign.name',
      json_value: 'true',
      use_field: 'name'
    },
    consolidator_id: {
      label: 'Consolidador',
      column: 'consolidator_name',
      select_id: 'claim_consolidator-select'
    },
    client_language: {
      label: 'Idioma',
      select_id: 'claim-language-select',
      column: 'client.language'
    },
    domain: { label: 'Dominio', select_id: 'claim-domain-select', column: 'domain' },
    negotiated_flight: {
      label: 'Vuelo negociado',
      column: 'flight_details.negotiated',
      type: 'boolean'
    },
    resolution_type: { label: 'Tipo de resolución', column: 'resolution_type' },
    wonflight: { label: 'Vuelo ganado', column: 'wonflight', type: 'boolean' },
    legal_type: { label: 'Ley vuelo', column: 'legal_type' },
    no_show: { label: 'No show', column: 'no_show' },
    client_has_bank_account: {
      label: 'Cuenta bancaria',
      column: 'client_has_bank_account',
      type: 'boolean'
    },
    client_has_paypal_account: {
      label: 'Cuenta Paypal',
      column: 'client_has_paypal_account',
      type: 'boolean'
    },
    desisted_state: { label: 'Estado desistido', column: 'desisted_state' },
    temporal_spain: { label: 'Temporal España', column: 'temporal_spain', type: 'boolean' },
    aesa_complaint: { label: 'Queja a AESA', column: 'aesa_complaint', type: 'boolean' },
    date_finished: { label: 'Fecha finalización', column: 'date_finished' },
    validated_flight: { label: 'Vuelo validado', column: 'flight.is_validated' },
    claim_type: { label: 'Tipo reclamación' },
    filter: { label: 'Filtro complejo' },
    possible_force_majeure: { label: 'Posible fuerza mayor' },
    flight_has_unfavorable_sentences: {
      label: 'Vuelo con sentencias desfavorables',
      type: 'boolean'
    },
    bypass: { label: 'Puenteo', column: 'bypass' },
    procurado: { label: 'Procurador', column: 'procurado', type: 'boolean' },
    partner: { label: 'Partner', column: 'partner' },
    partner_tracking_id: { label: 'Partner Tracking', column: 'partner_tracking_id' },
    boarding_pass_uploaded: {
      label: 'Tiene tarjeta de embarque',
      column: 'boarding_pass_uploaded',
      type: 'boolean'
    },
    digital_sign_via_signaturit: {
      label: 'Firma digital a través de Signaturit',
      column: 'claim.digital_sign_via_signaturit',
      type: 'boolean'
    },
    goals: { label: 'Objetivos' },
    lawyer__username: { label: 'Abogado' },
    client_attendance: { label: 'Asistencia a juicio', select_id: 'client_attendance-select' },
    applied_lawyer_type_sla: { label: 'SLAs vencidos', type: 'boolean' },
    expiration_lawyer_type_sla_datetime__lte: { label: 'SLAs a punto de vencer', type: 'date' },
    reimbursement_major_cause: { label: 'Solo billete', type: 'boolean' },
    more_14_days: { label: 'Cancelación +14 días', type: 'boolean' }
  },

  defaultColumnDef_flights: [
    { headerName: 'Vuelo', fieldName: 'flight', sortable: true, alwaysVisible: true },
    { headerName: 'Aerolinea', fieldName: 'airline_name', sortable: true, alwaysVisible: true },
    { headerName: 'Salida Planif.', fieldName: 'scheduled_departure', sortable: true, hidden: false },
    { headerName: 'Salida Real', fieldName: 'actual_departure', sortable: true, hidden: false },
    { headerName: 'A. Salida', fieldName: 'airport_departure_city_es', sortable: true, hidden: false },
    { headerName: 'Llegada Planif.', fieldName: 'scheduled_arrival', sortable: true, hidden: false },
    { headerName: 'Llegada Real', fieldName: 'actual_arrival', sortable: true, hidden: false },
    { headerName: 'A. Llegada', fieldName: 'airport_arrival_city_es', sortable: true, hidden: false },
    { headerName: 'Distancia', fieldName: 'flight_distance', sortable: true },
    { headerName: 'Retraso', fieldName: 'delay', sortable: true },
    { headerName: 'Incidencia', fieldName: 'status', sortable: true }
  ],
  filterFieldInfo_flights: {
    airport_departure_id: {
      label: 'Aeropuerto de salida',
      column: 'airport_departure',
      json_value: 'true',
      use_field: 'id'
    },
    airport_arrival_id: {
      label: 'Aeropuerto de llegada',
      column: 'airport_arrival',
      json_value: 'true',
      use_field: 'id'
    },
    airline_id: { label: 'Aerolínea', column: 'airline', json_value: 'true', use_field: 'id' },
    distance: { label: 'Distancia', column: 'distance', type: 'flight_distance' },
    scheduled_departure__gte: {
      label: 'Fecha planif. salida desde',
      type: 'date',
      column: 'scheduled_departure',
      order: '>='
    },
    scheduled_departure__lte: {
      label: 'Fecha planif. salida hasta',
      type: 'date',
      column: 'scheduled_departure',
      order: '<='
    },
    scheduled_arrival__gte: {
      label: 'Fecha planif. llegada desde',
      type: 'date',
      column: 'scheduled_arrival',
      order: '>='
    },
    scheduled_arrival__lte: {
      label: 'Fecha planif. llegada hasta',
      type: 'date',
      column: 'scheduled_arrival',
      order: '<='
    },
    actual_departure__gte: { label: 'Fecha real salida desde', type: 'date', column: 'actual_departure', order: '>=' },
    actual_departure__lte: { label: 'Fecha real salida hasta', type: 'date', column: 'actual_departure', order: '<=' },
    actual_arrival__gte: { label: 'Fecha real llegada desde', type: 'date', column: 'actual_arrival', order: '>=' },
    actual_arrival__lte: { label: 'Fecha real llegada hasta', type: 'date', column: 'actual_arrival', order: '<=' },
    status: { label: 'Incidencia', column: 'status' }
  }
};
