/**
 * state manager Controller
 *
 * Handles all the claim logic for lawyers / salesmen / lawyerstates / salestates.
 * - On claim updates, reload and process the data to enforce bussines logic.
 * - Displays basic modals for salesmen / lawyerstates / salestates changes.
 * - Display full state change modal, fully validated and unified between segment.
 * - Contain state change request logic.
 */

import angular from 'angular';
import $ from 'jquery';

StateManagerCtrl.$inject = [
  '$scope',
  '$sce',
  '$document',
  'serverAddress',
  'notifications',
  'select2Factory',
  'constantsFactory',
  'stateManagerService'
];

export default function StateManagerCtrl (
  $scope,
  $sce,
  $document,
  serverAddress,
  notifications,
  select2Factory,
  constantsFactory,
  stateManagerService
) {
  let vm = this;

  Object.assign(vm, select2Factory, {
    // bindings
    parentClaim: null,
    lawyers: [],
    salesmen: [],
    legalStates: [],
    salesStates: [],
    documentTypes: [],
    segmentPrefix: null,
    statesWithPreviewEmail: [],
    djangoModel: null,

    // processed values
    Claim: null,
    lawyer: null,
    salesman: null,
    legalState: null,
    salesState: null,

    hasPreviewEmail: false,
    previewEmailUrl: null,
    previewEmailUrlModal: null,

    // stateChangeModal
    preparingStateChange: true, // display loader until we have state change info
    targetStateCheckShow: false, // switch display state change info
    displayPreviewEmailIframe: false, // switch display email
    displayPreviewEmailIframeModal: false,
    displayPreviewPrivateArea: false, // switch display private area preview
    displayPreviewPrivateAreaModal: false,
    targetStateDetails: false, // switch display state change actions
    stateChangeErrors: false, // check errors and define the state change is valid (0 errors)
    stateChangeWarnings: 0, // display no checkeables errors in state change
    hasCommunications: false, // check the state change has communications

    // modals
    lawyerUpdate,
    salesmanUpdate,
    modalSetSalesmanShow: false,
    modalSetLawyerShow: false,
    stateData: null,

    targetState: null, // displays main changes state modal
    targetSalesState: null,

    $onChanges, // internal componen method

    // methods
    initLawyer,
    initSalesman,
    initLegalState,
    initLegalStateChange,
    initSalesState,
    initSalesStateChange,
    saveLawyer,
    saveSalesman,
    saveSalesState,
    finishClaim,
    retryLleida,
    changeState,
    prepareStateChange,
    getChangeStateData,
    checkCommunications,
    checkTestChangeStateValid,
    checkTestChangeStateWarnings,
    getEmailPreviewUrl,
    getStateData,
    saveClaim,

    // constants
    forceMajeureReasons: [
      { id: 0, value: 'Por baja visibilidad' },
      { id: 1, value: 'Por vientos fuertes' },
      { id: 2, value: 'Por huelga de controladores aéreos' },
      { id: 3, value: 'Por impacto de ave en el avión' },
      { id: 4, value: 'Por impacto de rayo en el avión' },
      { id: 5, value: 'Por cola de retrasos de vuelos' },
      { id: 6, value: 'Otros motivos' }
    ]
  });

  $scope.$watch('vm.targetState', vm.prepareStateChange);
  $scope.$on('claim:updated', claimUpdateCb);
  $scope.$on('claim:update:error', claimUpdateErrorsCb);
  $scope.$on('claim:setNewState', (e, newStateId) => {
    vm.legalState = newStateId;
    vm.initLegalStateChange();
  });

  // load DateOptions and create a settings obj for dates after now
  // TODO: review constatsFactory and keep this ther
  vm.dateOptions = constantsFactory.getDateOptions();
  vm.dateOptionsFromNow = angular.copy(vm.dateOptions);
  vm.dateOptionsFromNow.minDate = new Date();

  // On bindings changes, process updates in the important fields when ready
  // TODO fix basedetail dictionaries vm.busy :(
  function $onChanges () {
    if(vm.parentClaim && vm.parentClaim.id) {
      vm.claim = angular.copy(vm.parentClaim);
      if(vm.lawyers.length) vm.initLawyer(vm.claim.lawyer);
      if(vm.salesmen && vm.salesmen.length) vm.initSalesman(vm.claim.salesman);
      if(vm.legalStates.length) vm.initLegalState(vm.claim.state);
      if(vm.salesStates.length) vm.initSalesState(vm.claim.sales_state);
    }
  };

  function initLawyer(claimLawyer) {
    vm.lawyer = null;

    if(claimLawyer) {
      let lawyer = vm.lawyers.find(e => e.resource_uri == claimLawyer);
      if(lawyer) vm.lawyer = lawyer.id;
    }
  }

  function initSalesman(claimSalesman) {
    vm.salesman = null;

    if(claimSalesman) {
      let salesman = vm.salesmen.find(e => e.resource_uri == claimSalesman);
      if(salesman) vm.salesman = salesman.id;
    }
  }

  function initLegalState(claimState) {
    vm.targetState = null;
    vm.legalState = null;

    if(claimState) {
      let legalState = vm.legalStates.find(e => e.id == claimState.id);

      if(legalState) vm.legalState = legalState.id;

      vm.previewEmailUrl = vm.statesWithPreviewEmail.indexOf(claimState.id) != -1 ? vm.getEmailPreviewUrl(claimState.id) : null;
      vm.getStateData(claimState.id);
    }
  }

  function initSalesState(claimSalesState) {
    vm.targetSalesState = null;
    vm.salesState = null;

    if(claimSalesState) {
      let salesState = vm.salesStates.find(e => e.resource_uri == claimSalesState);

      if(salesState) vm.salesState = salesState.id;
    }
  }

  // Event methods
  // reset forms when data is update
  function claimUpdateCb() {
    // console.log(data);
  }

  function claimUpdateErrorsCb(evt, data) {
    switch (data.requester) {
      case 'stateManager:saveLawyer':
        // in saveLawyer error, notify and reset original lawyer
        vm.initLawyer(vm.claim.lawyer);
        notifications.addCurrentView('error', 'No se pudo completar el cambio de abogado.');
        break;
      case 'stateManager:saveSalesman':
        // in saveSalesman error, notify and reset original salesman
        vm.initSalesman(vm.claim.salesman);
        notifications.addCurrentView('error', 'No se pudo completar cambio de comercial.');
        break;
    };
  }

  function saveLawyer() {
    $scope.$emit('claim:update', {
      requester: 'stateManager:saveLawyer',
      fields: {
        lawyer: vm.lawyer ? vm.lawyers.find(e => e.id == vm.lawyer).resource_uri : null }});
  }

  function saveSalesman() {
    $scope.$emit('claim:update', {
      requester: 'stateManager:saveSalesman',
      fields: {
        salesman: vm.salesman ? vm.salesmen.find(e => e.id == vm.salesman).resource_uri : null }});
  }

  function saveSalesState() {
    $scope.$emit('claim:update', {
      requester: 'stateManager:saveSalesState',
      fields: {
        sales_state: vm.targetSalesState == 'noSalesState' ? null : vm.salesStates.find(e => e.id == vm.targetSalesState).resource_uri}});
  }

  function saveClaim() {
    $scope.$emit('claim:updateFull', vm.claim);
  }

  // detect select changes
  function lawyerUpdate() {
    if(vm.lawyer && vm.lawyers.find(e => e.id == vm.lawyer).resource_uri == vm.claim.lawyer) return;
    vm.modalSetLawyerShow = true;
  }

  function salesmanUpdate() {
    if(vm.salesman && vm.salesmen.find(e => e.id == vm.salesman).resource_uri == vm.claim.salesman) return;
    vm.modalSetSalesmanShow = true;
  }

  // update methods
  function finishClaim() {
    $scope.$emit('claim:finish');
  }

  function retryLleida(claimId, claimPrefix) {
    stateManagerService.retryLleida(claimId, claimPrefix)
      .then(response => {
        notifications.addCurrentView('info', response.data);
      })
      .catch(response => {
        notifications.addCurrentView('error', response.data);
      });
  }

  // Change state
  function initLegalStateChange() {
    // cases to prevent initiate state change
    if(!vm.claim.finished || vm.claim.sales_state || !vm.claim.lawyer) {
      let msj = 'no puede iniciarse el cambio de estado.';

      if(!vm.claim.finished) {
        msj = 'La reclamación no está finalizada: ' + msj;
      } else if(vm.claim.sales_state) {
        msj = 'La reclamación tiene estado comercial: ' + msj;
      } else {
        msj = 'La reclamación no tiene abogado asociado: ' + msj;
      }

      notifications.addCurrentView('error', msj);
      vm.initLegalState(vm.claim.state);

    // start state change
    } else {
      if(vm.claim.state && vm.legalState !== vm.claim.state.id) {
        vm.targetState = vm.legalState;
      } else {
        vm.targetState = null;
      }
    }
  }

  function initSalesStateChange() {
    if(vm.salesState == vm.salesStates.filter(e => e.resource_uri == vm.claim.sales_state).map(e => e.id)[0]) {
      vm.targetState = null;
      return;
    }

    vm.targetSalesState = vm.salesState ? vm.salesState : 'noSalesState'; // fix empty values
  }

  function getStateData(legalState) {
    vm.getChangeStateData(legalState)
      .then(response => {
        vm.stateData = response.data;
      })
      .catch(() => {
        notifications.addCurrentView('error', 'Se produjo un error cargando la información de estado.');
      });
  }

  // prepare stateChange modal or cleear it
  function prepareStateChange() {
    if(!vm.claim) return;

    if(vm.targetState) { // modal displayed
      // initiate state
      vm.preparingStateChange = true; // display loader until we have state change info
      vm.displayPreviewEmailIframeModal = false; // display loader until we have state change info
      vm.targetStateCheckShow = false;
      vm.displayPreviewPrivateAreaModal = false;
      vm.targetStateDetails = false;
      vm.stateChangeErrors = null; // check errors and define the state change is valid (0 errors)
      vm.stateChangeWarnings = null;
      vm.hasCommunications = false;

      $document.on('keyup', escapeHandler);
      $('body').addClass('modal-open');

      // Request to validates change from the server
      vm.getChangeStateData(vm.targetState)
        .then(response => {
          vm.targetStateData = response.data;
          vm.targetStateCheckShow = false;
          vm.stateChangeErrors = vm.checkTestChangeStateValid(vm.targetStateData);
          vm.stateChangeWarnings = vm.checkTestChangeStateWarnings(vm.targetStateData);
          vm.hasCommunications = vm.checkCommunications(vm.targetStateData);

          // remove loader
          vm.preparingStateChange = false;
        })
        .catch(() => {
          vm.targetState = false;
          notifications.addCurrentView('error', 'Se produjo un error preparando el cambio de estado.');
        });

    } else { // modal hidden
      $document.off('keyup', escapeHandler);
      $('body').removeClass('modal-open');
    }

    // Display preview email button
    vm.hasPreviewEmail = vm.statesWithPreviewEmail.indexOf(vm.targetState) !== -1;
    if(!vm.hasPreviewEmail) return;

    // prepare preview email button url
    vm.previewEmailUrlModal = vm.getEmailPreviewUrl(vm.targetState);
  }

  function getEmailPreviewUrl(targetState) {
    let URL = `common/claim/generate-preview-email/?claim_id=${vm.claim.id}&preview_state_id=${targetState}&segment_prefix=${vm.segmentPrefix}`;
    URL = serverAddress.getBaseUrl() + URL;

    // Specific case: Juicio señalado will pass extra info here
    if (vm.claim.legalfile && vm.claim.legalfile.client_attendance) {
      URL += '&client_attendance=' + vm.claim.legalfile.client_attendance;
    }

    return $sce.trustAsResourceUrl(URL);
  }

  // close modal by keyDown
  function escapeHandler(e) {
    if (e.key == 'Escape') $scope.$apply(() => vm.initLegalState(vm.claim.state));
  };

  // TODO: back should receive a full claim object and not this crazy object
  function getChangeStateData(state) {
    let requestData = {
      django_class: vm.djangoModel,
      claim_id: vm.claim.id,
      preview_state_id: state
    };

    return stateManagerService.getChangeStateData(requestData);
  }

  // Remove complex  set boolean if any communication
  function checkCommunications(stateChangeData) {
    return (stateChangeData.state_change_info.transition &&
      (stateChangeData.state_change_info.transition.send_mail || stateChangeData.state_change_info.transition.send_sms)) ||
      stateChangeData.private_area_info;
  }

  // check all stateChange checks are valid
  function checkTestChangeStateValid(stateChangeData) {
    const CHANGE = stateChangeData.state_change_info;

    // if some of this are false => invalid
    const CHECKS = ['not_sales_state', 'is_finished', 'has_lawyer', 'has_required_airline']; //, 'user_allowed'];
    const CHECKS_KO = CHECKS.filter(e => !CHANGE.claim[e]).length;
    if(!CHANGE.transition) return CHECKS_KO;

    const TRANSITION = CHANGE.transition;

    // required_states is an array of arrays with the form [stateName, theClaimHasBeenInThatState]
    const STATES_KO = TRANSITION.required_states.filter(e => !e[1]).length;
    // required_docs is an array of arrays with the form [documentName, theClaimHasTheDocument]
    const DOCS_KO = TRANSITION.required_docs.filter(e => !e[1]).length;
    const CONDITIONS_KO = TRANSITION.conditions.filter(e => e[2].checkeable && !e[1]).length;
    return STATES_KO + DOCS_KO + CHECKS_KO + CONDITIONS_KO;
  }

  // check failed stateChange checks but not required
  function checkTestChangeStateWarnings(stateChangeData) {
    const CHANGE = stateChangeData.state_change_info;
    if(!CHANGE.transition) return 0;

    const TRANSITION = CHANGE.transition;
    return TRANSITION.conditions.filter(e => !e[2].checkeable && !e[1]).length;
  }

  // TODO: back should receive a full claim object and not this crazy object
  function changeState(sendEmail) {
    let requestData = {
      django_class: vm.djangoModel,
      claim_id: vm.claim.id,
      new_state_name: vm.legalStates.find(e => e.id == vm.targetState).name,
      comment: vm.changeStateComment,
      agreement_amount: Number(vm.claim.agreement_amount),
      notary_id: vm.claim.notary ? vm.claim.notary.id : null,
      notary_alt: (vm.claim.notary && vm.claim.notary.id == 1) ? vm.claim.notary_alternative : null,
      sending_email: sendEmail,
      client_attendance: vm.claim.legalfile && typeof vm.claim.legalfile.client_attendance !== 'undefined' ? vm.claim.legalfile.client_attendance : null,
      is_immediate: vm.claim.is_immediate,
      compensation_type: vm.claim.compensation_type,
      billing_date: vm.claim.billing_date
    };

    let serviceURL = 'common/claim/state/change/';

    switch(vm.segmentPrefix) {
      case 'NE':
        requestData.params = { obtained_amount: vm.claim.obtained_amount || 0 };
        break;

      case 'TA':
        requestData.params = { obtained_amount: vm.claim.obtained_amount || 0 };
        requestData.presumed_resolution = vm.claim.presumed_resolution;
        requestData.team_city = vm.claim.team_city;
        break;

      case 'LA':
        requestData.params = { smac_date: vm.claim.smac_date };
        break;

      case 'TE':
        serviceURL = 'phone-claim/state/change/';

        requestData.claimamount = vm.claim.claimamount;
        requestData.existing_claim_date = vm.claim.company_answer_deadline;
        requestData.via = vm.claim.via;
        break;

      case 'AE':
        serviceURL = 'claim/state/change/';

        requestData.voucher_type = vm.claim.voucher_type;
        requestData.agreement_ref = vm.claim.agreement_ref;
        requestData.flight_ticket_voucher = vm.claim.flight_ticket_voucher;
        requestData.notary_deadline = vm.claim.notary_deadline;
        requestData.paid_amount = Number(vm.claim.paid_amount);
        requestData.extLawyerProv = vm.extLawyerProv;
        requestData.no_trial_attendance_reason = vm.claim.no_trial_attendance_reason;
        requestData.arbitration_amount = Number(vm.claim.arbitration_amount);
        requestData.force_majeure_reason = vm.claim.force_majeure_reason;
        break;
    };

    // WARNING
    // client_attendance and no_trial_attendance reason are used in states 170 and 198. And no_trial_attendance_reason
    // is bound, via ng-model, to 2 different DOM elements (a textarea and 2 radio buttons, in the case of selecting
    // those states)
    stateManagerService.changeState(serviceURL, requestData)
      .then(() => {
        vm.changeStateComment = null;
        vm.targetState = null;

        $scope.$emit('claim:refresh', {
          msj: 'Cambio de estado completado'});
      })
      .catch(response => {
        notifications.addCurrentView('error', response.data);
      });
  }
}